@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  }

* {
    font-family: 'Poppins';
}

.chat-selected {
  border-left: 3px solid #4177F6;
}

.rce-mbox-right {
  background-color: #ecf1fe !important;
}

.rce-mbox-right-notch {
  fill: #ecf1fe !important; 
}

.message-list-scroll {
  overflow-y: auto;
}

.rce-avatar-letter-background {
  width: 50px !important;
  height: 50px !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.rce-avatar-letter {
  margin-top: 0 !important;
}